import {
  SEE_ALL_LINK_DESIGN_PARAMS,
  SEE_ALL_LINK_TEXT_DESIGN_PARAMS,
  SEE_ALL_LINK_BUTTON_DESIGN_PARAMS,
} from '@wix/communities-blog-client-common';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { AppState } from '../../common/types';

const LAYOUTS = SEE_ALL_LINK_DESIGN_PARAMS.layoutType.values;

export const getSeeAllLinkLayout = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: SEE_ALL_LINK_DESIGN_PARAMS.layoutType.appSettingsPath,
    fallback: SEE_ALL_LINK_DESIGN_PARAMS.layoutType.defaultValue,
  }) as (typeof LAYOUTS)[keyof typeof LAYOUTS];

export const getIsSeeAllLinkLayoutText = (state: AppState) =>
  getSeeAllLinkLayout(state) === LAYOUTS.text;

export const getIsSeeAllLinkUppercase = (state: AppState) => {
  const seeAllLinkLayout = getSeeAllLinkLayout(state);

  const params = {
    [LAYOUTS.text]: SEE_ALL_LINK_TEXT_DESIGN_PARAMS,
    [LAYOUTS.button]: SEE_ALL_LINK_BUTTON_DESIGN_PARAMS,
  }[seeAllLinkLayout];

  return getAppSettingsValue({
    state,
    key: params.uppercase.appSettingsPath,
    fallback: params.uppercase.defaultValue,
  });
};
